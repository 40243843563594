import React, { useState, useEffect } from "react";
import { ChevronDown } from "lucide-react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const FAQItem = ({
  question,
  children,
}: {
  question: string;
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border border-gray-200 rounded-md mb-4">
      <button
        className="w-full text-left p-4 flex justify-between items-center focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-medium">{question}</span>
        <ChevronDown
          className={`transform transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </button>
      {isOpen && <div className="p-4 pt-0 text-gray-600">{children}</div>}
    </div>
  );
};

const FAQSection = () => {
  const faqs = [
    { question: "What is ChatGPT?" },
    { question: "How can ChatGPT help me?" },
    {
      question: "Is ChatGPT available in multiple languages?",
    },
    {
      question: "How accurate is ChatGPT?",
    },
    {
      question: "Can I use ChatGPT for professional or academic purposes?",
    },
    {
      question: "Are there different subscription plans for ChatGPT?",
    },
    { question: "Is there a free version of ChatGPT?" },
    {
      question: "How is my privacy protected when using ChatGPT?",
    },
  ];

  const answers = [
    "ChatGPT is an advanced AI language model developed by OpenAI. It's designed to understand and generate human-like text, providing responses to a wide range of questions and assisting with various tasks.",
    "ChatGPT can help you with writing, answering questions, problem-solving, brainstorming ideas, explaining complex topics, and much more. It's a versatile tool that can assist in numerous personal and professional tasks.",
    "Yes, ChatGPT is capable of understanding and generating text in multiple languages. However, its proficiency may vary depending on the language and the specific version of the model being used.",
    "ChatGPT's accuracy can be quite high, especially for general knowledge and common tasks. However, it's important to note that it can sometimes make mistakes or provide outdated information. It's always a good idea to verify important information from authoritative sources.",
    "Absolutely! ChatGPT can be a valuable tool for both professional and academic purposes. It can help with research, writing, coding, problem-solving, and more. However, it's important to use it as a supportive tool and not rely on it entirely, especially for critical or specialized tasks.",
    "Yes, OpenAI offers different subscription plans for ChatGPT, including a free tier and a premium subscription called ChatGPT Plus. The premium plan offers benefits like faster response times, priority access during peak times, and access to new features.",
    "Yes, there is a free version of ChatGPT available to the public. While it may have some limitations compared to the paid version, it still offers a wide range of capabilities and can be incredibly useful for many tasks.",
    "OpenAI takes privacy seriously. They don't use your conversations to train their models, and they have strict data handling policies. However, it's always advisable to avoid sharing sensitive personal information in your conversations with ChatGPT.",
  ];

  return (
    <div className="bg-white text-black p-1 mt-10">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold mb-8 text-center regular">
          Frequently Asked Questions
        </h1>
        <p className="text-center mb-8 text-gray-600">
          Contact us at{" "}
          <a
            href="mailto:hello@rendernet.ai"
            className="text-blue-600 hover:underline"
          >
            hello@joinchatgptplus.com{" "}
          </a>
          for any additional queries and concerns
        </p>
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question}>
            <p>{answers[index]}</p>
          </FAQItem>
        ))}
      </div>
    </div>
  );
};

const PricingPage = () => {
  const [billingCycle, setBillingCycle] = useState("weekly");

  const handleUnlock = (type: string) => {
    ReactGA.event({
      category: "User",
      action: "Clicked Start Trial",
    });

    let isDev = process.env.NODE_ENV === "development";
    if (isDev) {
      window.location.href = "https://buy.stripe.com/test_6oE3cnf8HaGna4g000";
      return;
    }

    // const weekly = "https://buy.stripe.com/3cs6ou1pffnP5Qk14q";
    // const yearly = "https://buy.stripe.com/bIY28e8RH5Nfa6A14r";

    const weekly = "https://buy.stripe.com/cN25kqd7XfnPbaEeVi"
    const yearly = "https://buy.stripe.com/bIY7sy1pfejL6Uo8wV"

    if (type === "Yearly") {
      window.location.href = yearly;
    } else if (type === "Weekly") {
      window.location.href = weekly;
    }
  };

  const plans = [
    {
      name: "Yearly",
      monthlyPrice: 0.5,
      annualPrice: 6,
      period: "per week, billed yearly",
      credits: "Unlimited",
      creditPeriod: "month",
      parallelGenerations: 50,
      features: [
        "Access to GPT-4o, our most advanced model",
        "Unlimited usage",
        "Faster response times",
        "Photo and file analysis capabilities",
        "Voice interaction support",
        "Priority access during peak times",
        "Early access to new features",
      ],
      isMostBought: true,
    },
    {
      name: "Weekly",
      monthlyPrice: 2,
      annualPrice: 3,
      period: "per week",
      credits: 100,
      creditPeriod: "month",
      parallelGenerations: 10,
      features: [
        "Access to GPT-4o, our most advanced model",
        "Unlimited usage",
        "Faster response times",
        "Photo and file analysis capabilities",
        "Voice interaction support",
        "Priority access during peak times",
        "Early access to new features",
      ],
    },
  ];

  const [screenSize, setScreenSize] = useState<number>(window.innerWidth);

  const handleStartTrial = () => {
    localStorage.setItem("isLoggedIn", "true");
    window.location.href = "/chat";
  };

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("isLoggedIn", "true");
    const hasPaid = localStorage.getItem("hasPaid") === "true";
    if (hasPaid) {
      navigate("/chat");
    }
  }, [navigate]);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isDesktop = screenSize >= 768;

  return (
    <div className="bg-white text-black min-h-screen p-6 pt-8 font-sans">
      <div className="flex items-center justify-center w-full">
        <div
          className={`flex flex-col items-center mb-8 ${
            screenSize >= 768 ? "md:flex-row md:justify-center" : ""
          }`}
        >
          <img
            src="https://storage.googleapis.com/glowupthenblowup.appspot.com/gpt_logo.png"
            alt=""
            className={`${
              screenSize < 414 ? "w-[44px] h-[44px]" : "w-[51px] h-[51px]"
            } ${screenSize >= 768 ? "md:mr-2" : "mb-2"}`}
          />
          <h1
            className="font-bold regular text-center md:text-left"
            style={{
              fontSize: screenSize < 414 ? "22px" : "25px",
            }}
          >
            To continue, start 7-day free trial.
          </h1>
        </div>
      </div>

      <div className="flex justify-center mb-8">
        <div className="flex bg-gray-100 rounded-full p-1">
          <button
            className={`px-6 py-2 rounded-full ${
              billingCycle === "weekly" ? "bg-white shadow" : ""
            }`}
            onClick={() => setBillingCycle("weekly")}
          >
            Weekly
          </button>
          <button
            className={`px-6 py-2 rounded-full ${
              billingCycle === "annually" ? "bg-white shadow" : ""
            }`}
            onClick={() => setBillingCycle("annually")}
          >
            Yearly
          </button>
          <button
            className="bg-[#0CA37F] text-white px-4 py-2 rounded-full text-sm ml-2"
            onClick={() => setBillingCycle("annually")}
          >
            75% Off
          </button>
        </div>
      </div>

      <div
        className={`grid grid-cols-1 md:grid-cols-1 gap-8 max-w-2xl mx-auto ${
          isDesktop ? "mt-8" : ""
        }`}
      >
        {plans.map((plan) => {
          if (billingCycle === "annually" && plan.name === "Weekly") {
            return null;
          }

          if (billingCycle === "weekly" && plan.name === "Yearly") {
            return null;
          }

          return (
            <div
              key={plan.name}
              className={`bg-white border ${
                plan.name == "Yearly"
                  ? "border-[#0CA37F] border-2"
                  : "border-gray-200"
              } rounded-lg p-6 flex flex-col relative`}
            >
              {plan.name == "Yearly" && (
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#0CA37F] text-white px-4 py-1 rounded-full text-sm">
                  Most Popular
                </div>
              )}
              <div className="text-2xl font-bold mb-2 regular">{plan.name}</div>
              <div className="flex items-center mb-1">
                <div className="text-3xl font-bold regular mr-2">
                  $
                  {billingCycle === "annually"
                    ? plan.monthlyPrice.toFixed(2)
                    : plan.monthlyPrice}
                </div>
                {plan.name == "Yearly" && (
                  <div className="text-3xl text-gray-300 regular line-through">
                    $
                    {billingCycle === "annually"
                      ? plan.monthlyPrice * 4
                      : plan.monthlyPrice * 4}
                  </div>
                )}
              </div>
              <p className="text-gray-500 mb-4">
                {billingCycle === "annually"
                  ? `per week, billed $${plan.monthlyPrice * 52} yearly`
                  : plan.period}
              </p>
              <button
                onClick={() => handleUnlock(plan.name)}
                className={`py-3 px-4 rounded-[4px] w-full mb-6 ${"bg-[#0CA37F] text-white"}`}
              >
                {"Start Trial"}
              </button>
              <ul className="space-y-2">
                {plan.features.map((feature, index) => (
                  <li key={index} className="flex items-center">
                    <span
                      className={`mr-2 ${
                        plan.name === "Free" ? "text-red-500" : "text-green-500"
                      }`}
                    >
                      {plan.name === "Free" ? "✕" : "✓"}
                    </span>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>

      <div className="text-center text-gray-500 text-sm mt-8">
        <p className="mt-2">
          You can cancel your subscription anytime. You'll still be able to use
          your plan until the end of your current billing period.
        </p>
        <p className="mt-2">
          To cancel, click 'Manage subscription' on the Account screen.
        </p>
      </div>
      <FAQSection />
      <p className="text-sm text-gray-500 text-center p-8 pt-0">
        Copyright 2024 ChatGPT Plus. All rights reserved.
        <br />
      </p>
    </div>
  );
};

export default PricingPage;
