import React, { useState, useEffect, useRef } from "react";
import { Send, Paperclip, Plus, User, ArrowUp, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { useAuth0 } from "@auth0/auth0-react";
import ReactGA from "react-ga4";

const OPENAI_API_KEY = process.env.OPENAI_API_KEY;
const API_BASE_URL = "https://api.openai.com/v1";
const ASSISTANT_ID = "asst_gKz2bcLcm6FZ7ejkQ8uK6mGI";
const GOOGLE_CLOUD_STORAGE_API_URL =
  "https://storage.googleapis.com/upload/storage/v1/b/gpt_files/o";

interface Message {
  role: "user" | "assistant";
  content:
    | string
    | Array<{
        type: "text" | "image_url";
        text?: string;
        image_url?: { url: string; detail?: "auto" | "low" | "high" };
      }>;
}

interface Chat {
  threadId: string;
  messages: Message[];
}

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState("profile");

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-md">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-sm regular">Settings</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X size={24} />
          </button>
        </div>
        <div className="p-4">
          <div className="flex mb-4">
            <button
              className={`text-sm mr-4 px-3 py-2 rounded ${
                activeTab === "profile" ? "bg-gray-100" : ""
              }`}
              onClick={() => setActiveTab("profile")}
            >
              Profile
            </button>
            <button
              className={`text-sm mr-4 px-3 py-2 rounded ${
                activeTab === "manage" ? "bg-gray-100" : ""
              }`}
              onClick={() => setActiveTab("manage")}
            >
              Manage
            </button>
          </div>
          {activeTab === "profile" && <ProfileSettings />}
          {activeTab === "manage" && <Manage />}
        </div>
      </div>
    </div>
  );
};

const ProfileSettings: React.FC = () => {
  const { user, isLoading, error } = useAuth0();

  console.log("User:", user);

  if (isLoading) {
    return <div>Loading user information...</div>;
  }

  if (error) {
    return <div>Error loading user information: {error.message}</div>;
  }

  return (
    <div className="space-y-4">
      <div>
        {/* <h3 className="text-sm regular mb-2">Personal Information</h3> */}
        <p className="w-full text-sm p-0">{user?.name || "Not provided"}</p>
        <p className="w-full text-sm p-0 mt-2">
          {user?.email || "Not provided"}
        </p>
      </div>
      <div>
        <h3 className="text-sm regular mb-2">Preferences</h3>
        <label className="flex items-center text-sm">
          <input type="checkbox" className="mr-2 text-sm" />
          Receive email notifications
        </label>
      </div>
    </div>
  );
};

const Manage: React.FC = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.setItem("isLoggedIn", "false");
    navigate("/");
  };

  return (
    <div className="space-y-4">
      <div>
        <p className={"text-sm"}>You are currently on the Pro plan</p>
      </div>
      <div>
        <button
          onClick={() => navigate("/cancel-reason")}
          className="text-gray-400 text-sm"
        >
          Cancel
        </button>
      </div>
      <div>
        <button onClick={handleLogout} className="text-red-400 text-sm">
          Log out
        </button>
      </div>
    </div>
  );
};

const CancellationReasonForm: React.FC = () => {
  const [reason, setReason] = useState("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Cancellation reason:", reason);
    // Handle cancellation logic here
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label htmlFor="reason" className="block mb-2">
          Please tell us why you're cancelling:
        </label>
        <textarea
          id="reason"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          className="w-full p-2 border rounded"
          rows={4}
          required
        />
      </div>
      <button
        type="submit"
        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
      >
        Confirm Cancellation
      </button>
    </form>
  );
};

const OpenAIAssistantChat: React.FC = () => {
  const [chats, setChats] = useState<Chat[]>([]);
  const [currentChatIndex, setCurrentChatIndex] = useState<number | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [assistantId] = useState(ASSISTANT_ID);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const initialChatCreated = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMessages = async () => {
      const storedThreadId = localStorage.getItem("threadId");
      if (storedThreadId) {
        try {
          const response = await fetch(
            `${API_BASE_URL}/threads/${storedThreadId}/messages`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${OPENAI_API_KEY}`,
                "Content-Type": "application/json",
                "OpenAI-Beta": "assistants=v2",
              },
            }
          );
          const data = await response.json();
          if (data.data && Array.isArray(data.data)) {
            const formattedMessages = data.data.map((message: any) => ({
              id: message.id,
              role: message.role,
              content: message.content[0].text.value,
              createdAt: new Date(message.created_at * 1000), // Convert timestamp to Date object
            }));

            console.log("Fetched messages:", formattedMessages);
            const newChat: Chat = {
              threadId: storedThreadId,
              messages: [],
            };
            setChats((prevChats) => {
              const updatedChats = [...prevChats, newChat];
              setCurrentChatIndex(updatedChats.length - 1);
              return updatedChats;
            });
            setMessages(formattedMessages.reverse());
            initialChatCreated.current = true;
          }
        } catch (error) {
          console.error("Error fetching messages:", error);
        }
      } else if (!initialChatCreated.current) {
        startNewChat();
        initialChatCreated.current = true;
      }
    };

    fetchMessages();
  }, []);

  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const createThread = async () => {
    try {
      console.log("Creating thread...");
      const response = await fetch(`${API_BASE_URL}/threads`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${OPENAI_API_KEY}`,
          "Content-Type": "application/json",
          "OpenAI-Beta": "assistants=v2",
        },
      });
      const data = await response.json();
      console.log("Thread created with ID:", data.id);

      // Save thread ID in local storage
      localStorage.setItem("threadId", data.id);

      return data.id;
    } catch (error) {
      console.error("Error creating thread:", error);
    }
  };

  const uploadFileToGoogleStorage = async (file: File): Promise<string> => {
    const fileName = encodeURIComponent(`${Date.now()}-${file.name}`);
    const formData = new FormData();
    formData.append("file", file, fileName);
    formData.append("contentType", file.type);

    try {
      const response = await fetch(
        `${GOOGLE_CLOUD_STORAGE_API_URL}?name=${fileName}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const url = `https://storage.googleapis.com/gpt_files/${fileName}`;
      console.log(
        `File ${fileName} (${file.type}) uploaded to Google Storage. URL: ${url}`
      );
      return url;
    } catch (error) {
      console.error("Error uploading file to Google Storage:", error);
      throw error;
    }
  };

  const createMessage = async (
    threadId: string,
    content: string,
    imageUrls: string[] = []
  ) => {
    try {
      console.log("Creating message in thread:", threadId);
      const messageContent: Message["content"] = [
        { type: "text", text: content },
      ];

      for (const url of imageUrls) {
        messageContent.push({ type: "image_url", image_url: { url } });
      }

      const messageData = {
        role: "user",
        content: messageContent,
      };

      console.log("Message data:", messageData);

      await fetch(`${API_BASE_URL}/threads/${threadId}/messages`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${OPENAI_API_KEY}`,
          "Content-Type": "application/json",
          "OpenAI-Beta": "assistants=v2",
        },
        body: JSON.stringify(messageData),
      });
      console.log("Message created successfully");
    } catch (error) {
      console.error("Error creating message:", error);
    }
  };

  const createRun = async (threadId: string) => {
    try {
      console.log("Creating run for thread:", threadId);
      const response = await fetch(`${API_BASE_URL}/threads/${threadId}/runs`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${OPENAI_API_KEY}`,
          "Content-Type": "application/json",
          "OpenAI-Beta": "assistants=v2",
        },
        body: JSON.stringify({
          assistant_id: assistantId,
          stream: true,
        }),
      });

      if (!response.body) {
        throw new Error("ReadableStream not supported");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = "";

      const processStreamResult = async (
        result: ReadableStreamReadResult<Uint8Array>
      ): Promise<void> => {
        if (result.done) {
          console.log("Stream completed");
          return;
        }

        buffer += decoder.decode(result.value, { stream: true });
        const lines = buffer.split("\n\n");
        buffer = lines.pop() || "";

        for (const line of lines) {
          const parts = line.split("\n");
          if (
            parts.length === 2 &&
            parts[0].startsWith("event: ") &&
            parts[1].startsWith("data: ")
          ) {
            const eventType = parts[0].slice(7);
            const data = parts[1].slice(6);
            if (data === "[DONE]") {
              console.log("Stream ended");
              setIsLoading(false);
            } else {
              try {
                const parsedData = JSON.parse(data);
                handleStreamEvent(eventType, parsedData);
              } catch (error) {
                console.error("Error parsing stream data:", error);
              }
            }
          }
        }

        return reader.read().then(processStreamResult);
      };

      return reader.read().then(processStreamResult);
    } catch (error) {
      console.error("Error creating run:", error);
    }
  };

  const handleStreamEvent = (eventType: string, data: any) => {
    console.log(`Received event: ${eventType}`, data);

    switch (eventType) {
      case "thread.message.delta":
        updateMessageContent(data);
        break;
      case "thread.run.completed":
        setIsLoading(false);
        updateCurrentChat();
        getCompleteMessage(data.thread_id, data.message_id);
        break;
      case "thread.created":
      case "thread.run.created":
      case "thread.run.queued":
      case "thread.run.in_progress":
      case "thread.run.requires_action":
      case "thread.run.incomplete":
      case "thread.run.failed":
      case "thread.run.cancelling":
      case "thread.run.cancelled":
      case "thread.run.expired":
      case "thread.run.step.created":
      case "thread.run.step.in_progress":
      case "thread.run.step.delta":
      case "thread.run.step.completed":
      case "thread.run.step.failed":
      case "thread.run.step.cancelled":
      case "thread.run.step.expired":
      case "thread.message.created":
      case "thread.message.in_progress":
      case "thread.message.completed":
      case "thread.message.incomplete":
        console.log(`${eventType} event received:`, data);
        // Handle these events if needed
        break;
      case "error":
        console.error("Error event received:", data);
        setIsLoading(false);
        // Handle error event (e.g., show an error message to the user)
        break;
      default:
        console.log("Unhandled event type:", eventType);
    }
  };

  const getCompleteMessage = async (threadId: string, messageId: string) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/threads/${threadId}/messages/${messageId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${OPENAI_API_KEY}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const messageData = await response.json();
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        const lastMessageIndex = updatedMessages.length - 1;
        if (
          lastMessageIndex >= 0 &&
          updatedMessages[lastMessageIndex].role === "assistant"
        ) {
          updatedMessages[lastMessageIndex] = {
            role: "assistant",
            content: messageData.content[0].text.value,
          };
        }
        return updatedMessages;
      });
    } catch (error) {
      console.error("Error fetching complete message:", error);
    }
  };

  const updateMessageContent = (messageDelta: any) => {
    console.log("Updating message content:", messageDelta);
    if (!messageDelta || !messageDelta.delta || !messageDelta.delta.content) {
      console.log("Invalid message delta, skipping update");
      return;
    }

    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];
      const lastMessage = updatedMessages[updatedMessages.length - 1];

      if (lastMessage && lastMessage.role === "assistant") {
        const newContent = messageDelta.delta.content[0].text.value || "";
        let updatedContent = lastMessage.content;

        // Replace '\n' with actual newline characters
        updatedContent += newContent.replace(/\\n/g, "\n");

        // Add extra newline after headings and sections
        if (typeof updatedContent === "string") {
          updatedContent = updatedContent.replace(/^(#+.*?)$/gm, "$1\n");
          updatedContent = updatedContent.replace(/^(-\s.*?)$/gm, "$1\n");
        }

        updatedMessages[updatedMessages.length - 1] = {
          ...lastMessage,
          content: updatedContent,
        };
      } else {
        const newContent = (messageDelta.delta.content[0].text.value || "")
          .replace(/\\n/g, "\n")
          // Add extra newline after headings and sections
          .replace(/^(#+.*?)$/gm, "$1\n")
          .replace(/^(-\s.*?)$/gm, "$1\n");

        updatedMessages.push({
          role: "assistant",
          content: newContent,
        });
      }

      return updatedMessages;
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    try {
      e.preventDefault();
      if (!input.trim() || currentChatIndex === null) return;

      // Refocus the input after sending the message
      const inputElement = document.querySelector(
        'input[type="text"]'
      ) as HTMLInputElement;
      if (inputElement) {
        inputElement.focus();
      }

      // Send GA event for message sent
      ReactGA.event({
        category: "Chat",
        action: "Message Sent",
        label: "User Message",
      });

      console.log("Submitting message");
      setIsLoading(true);

      const imageUrls = await Promise.all(
        files.map((file) => uploadFileToGoogleStorage(file))
      );

      let messageContent = input.trim();

      const newMessage: Message = {
        role: "user",
        content: [
          { type: "text", text: messageContent },
          ...imageUrls.map((url) => ({
            type: "image_url" as const,
            image_url: { url },
          })),
        ],
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);

      const currentChat = chats[currentChatIndex];
      await createMessage(currentChat.threadId, messageContent, imageUrls);
      setInput("");
      setFiles([]);

      await createRun(currentChat.threadId);
    } catch (error) {
      console.error("Error submitting message:", error);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files);
      const imageFiles = selectedFiles.filter((file) =>
        file.type.startsWith("image/")
      );

      if (imageFiles.length !== selectedFiles.length) {
        // Show error popup for non-image files
        alert("Please select only image files.");
        return;
      }

      console.log("Image files selected:", imageFiles.length);
      setFiles(imageFiles);
    }
  };

  const updateCurrentChat = () => {
    console.log("Updating current chat");
    setChats((prevChats) => {
      const updatedChats = [...prevChats];
      if (currentChatIndex !== null) {
        updatedChats[currentChatIndex] = {
          ...updatedChats[currentChatIndex],
          messages: messages,
        };
      }
      return updatedChats;
    });
  };

  const startNewChat = async () => {
    console.log("Starting new chat");
    const newThreadId = await createThread();
    if (newThreadId) {
      const newChat: Chat = {
        threadId: newThreadId,
        messages: [],
      };
      setChats((prevChats) => {
        const updatedChats = [...prevChats, newChat];
        setCurrentChatIndex(updatedChats.length - 1);
        return updatedChats;
      });
      setMessages([]);
    }
  };
  return (
    <div className="flex h-screen bg-white">
      {/* Profile modal */}
      <Modal
        isOpen={isProfileModalOpen}
        onClose={() => setIsProfileModalOpen(false)}
      />
      {/* Main chat area */}
      <div className="flex-1 flex flex-col">
        {/* Chat header */}
        <div className="bg-white shadow-sm p-4 flex items-center justify-between">
          <h1 className="text-xl regular">ChatGPT 4o</h1>
          <div className="flex items-center space-x-2">
            <button
              onClick={startNewChat}
              className="p-2 bg-gray-200 text-gray-600 rounded-full hover:bg-gray-300 focus:outline-none"
            >
              <Plus size={20} />
            </button>
            <button
              onClick={() => setIsProfileModalOpen(true)}
              className="p-2 bg-gray-200 text-gray-600 rounded-full hover:bg-gray-300 focus:outline-none"
            >
              <User size={20} />
            </button>
          </div>
        </div>

        {/* Messages area */}
        <div className="flex-1 overflow-y-auto p-4 space-y-4">
          <div className="max-w-3xl mx-auto">
            {messages.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-full">
                <div className="flex flex-col items-center justify-center mt-[230px]">
                  <img
                    src="https://storage.googleapis.com/glowupthenblowup.appspot.com/gpt_logo.png"
                    alt="GPT Logo"
                    className="w-32 h-32 mb-4"
                  />
                  <p className="text-xl text-gray-600">How can I help you?</p>
                </div>
              </div>
            ) : (
              messages.map((message, index) => (
                <div key={index} className="flex flex-col">
                  <div
                    className={`flex ${
                      message.role === "user" ? "justify-end" : "justify-start"
                    }`}
                  >
                    <div
                      className={`max-w-xs md:max-w-md lg:max-w-lg xl:max-w-xl rounded-full p-3 pl-5 pr-5 ${
                        message.role === "user"
                          ? "bg-gray-100 text-black"
                          : "bg-white"
                      }`}
                    >
                      {Array.isArray(message.content) ? (
                        message.content.map((content, i) => (
                          <div key={i}>
                            {content.type === "text" &&
                              (message.role === "user" ? (
                                <p>{content.text || ""}</p>
                              ) : (
                                <Markdown
                                  rehypePlugins={[rehypeRaw]}
                                  components={{
                                    h1: ({ node, ...props }) => (
                                      <h1
                                        className="text-2xl font-bold mt-4 mb-2"
                                        {...props}
                                      />
                                    ),
                                    h2: ({ node, ...props }) => (
                                      <h2
                                        className="text-xl font-bold mt-3 mb-2"
                                        {...props}
                                      />
                                    ),
                                    h3: ({ node, ...props }) => (
                                      <h3
                                        className="text-lg font-bold mt-3 mb-1"
                                        {...props}
                                      />
                                    ),
                                    h4: ({ node, ...props }) => (
                                      <h4
                                        className="text-base font-bold mt-2 mb-1"
                                        {...props}
                                      />
                                    ),
                                    p: ({ node, ...props }) => (
                                      <p className="mb-2" {...props} />
                                    ),
                                    ul: ({ node, ...props }) => (
                                      <ul
                                        className="list-disc list-inside mb-2"
                                        {...props}
                                      />
                                    ),
                                    ol: ({ node, ...props }) => (
                                      <ol
                                        className="list-decimal list-inside mb-2"
                                        {...props}
                                      />
                                    ),
                                    li: ({ node, ...props }) => (
                                      <li className="mb-1" {...props} />
                                    ),
                                    strong: ({ node, ...props }) => (
                                      <strong
                                        className="font-bold"
                                        {...props}
                                      />
                                    ),
                                    em: ({ node, ...props }) => (
                                      <em className="italic" {...props} />
                                    ),
                                  }}
                                >
                                  {content.text || ""}
                                </Markdown>
                              ))}
                          </div>
                        ))
                      ) : message.role === "user" ? (
                        <p>{message.content || ""}</p>
                      ) : (
                        <Markdown
                          rehypePlugins={[rehypeRaw]}
                          components={{
                            h1: ({ node, ...props }) => (
                              <h1
                                className="text-2xl font-bold mt-4 mb-2"
                                {...props}
                              />
                            ),
                            h2: ({ node, ...props }) => (
                              <h2
                                className="text-xl font-bold mt-3 mb-2"
                                {...props}
                              />
                            ),
                            h3: ({ node, ...props }) => (
                              <h3
                                className="text-lg font-bold mt-3 mb-1"
                                {...props}
                              />
                            ),
                            h4: ({ node, ...props }) => (
                              <h4
                                className="text-base font-bold mt-2 mb-1"
                                {...props}
                              />
                            ),
                            p: ({ node, ...props }) => (
                              <p className="mb-2" {...props} />
                            ),
                            ul: ({ node, ...props }) => (
                              <ul
                                className="list-disc list-inside mb-2"
                                {...props}
                              />
                            ),
                            ol: ({ node, ...props }) => (
                              <ol
                                className="list-decimal list-inside mb-2"
                                {...props}
                              />
                            ),
                            li: ({ node, ...props }) => (
                              <li className="mb-1" {...props} />
                            ),
                            strong: ({ node, ...props }) => (
                              <strong className="font-bold" {...props} />
                            ),
                            em: ({ node, ...props }) => (
                              <em className="italic" {...props} />
                            ),
                          }}
                        >
                          {message.content || ""}
                        </Markdown>
                      )}
                    </div>
                  </div>
                  {Array.isArray(message.content) &&
                    message.content.some(
                      (content) =>
                        content.type === "image_url" && content.image_url
                    ) && (
                      <div
                        className={`mt-2 ${
                          message.role === "user" ? "self-end" : "self-start"
                        }`}
                      >
                        {message.content.map(
                          (content, i) =>
                            content.type === "image_url" &&
                            content.image_url && (
                              <img
                                key={i}
                                src={content.image_url.url}
                                alt="Uploaded"
                                className="max-w-[350px] w-full h-auto rounded-lg"
                              />
                            )
                        )}
                      </div>
                    )}
                </div>
              ))
            )}
            <div ref={messagesEndRef} />
          </div>
        </div>

        {/* Input area */}
        <div className="p-4 bg-white">
          <div className="max-w-3xl mx-auto">
            <form onSubmit={handleSubmit} className="flex flex-col space-y-2">
              <div className="flex items-center space-x-2 bg-gray-100 rounded-full p-2">
                <button
                  type="button"
                  onClick={() => fileInputRef.current?.click()}
                  className="p-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                  disabled={isLoading}
                >
                  <Paperclip size={20} />
                </button>
                <input
                  type="text"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  placeholder="Message ChatGPT..."
                  className="flex-1 bg-transparent focus:outline-none"
                  //   disabled={isLoading}
                />
                <button
                  type="submit"
                  className={`p-2 rounded-full focus:outline-none disabled:opacity-50 ${
                    input.trim()
                      ? "bg-black text-white hover:bg-gray-800"
                      : "bg-gray-300 text-gray-600"
                  }`}
                  disabled={isLoading}
                >
                  <ArrowUp size={20} />
                </button>
              </div>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                multiple
                className="hidden"
                accept=".pdf,.png,.jpg,.jpeg,.gif,.txt,.csv,.xlsx"
              />
              {files.length > 0 && (
                <div className="text-sm text-gray-600">
                  Selected files: {files.map((f) => f.name).join(", ")}
                </div>
              )}
            </form>
            <p className="text-xs text-gray-500 mt-2 text-center">
              ChatGPT can make mistakes. Check important info.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenAIAssistantChat;
