import React, { useState, useRef, useEffect } from "react";
import {
  Play,
  Pause,
  Volume2,
  VolumeX,
  Maximize,
  Minimize,
  SkipBack,
  SkipForward,
} from "lucide-react";
import { v4 as uuidv4 } from "uuid";

const apiRoot =
  process.env.NODE_ENV !== "development"
    ? "https://bingebandit.onrender.com"
    : "http://localhost:8080";

const FullscreenVideoPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(true);
  const [showControls, setShowControls] = useState(true);
  const [magnetLink, setMagnetLink] = useState(null);
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const controlsTimeoutRef = useRef(null);
  const magnetLinkFetchedRef = useRef(false);
  const [clientId] = useState(uuidv4());

  useEffect(() => {
    const fetchMagnetLink = async () => {
      if (magnetLinkFetchedRef.current) return;

      magnetLinkFetchedRef.current = true;
      const urlParts = window.location.pathname.split("/");
      const title = urlParts[2];
      const year = urlParts[3];
      const mediaType = urlParts[4];
      const tmdb_id = urlParts[5];

      const externalIdsUrl = `https://api.themoviedb.org/3/${mediaType}/${tmdb_id}/external_ids`;
      const externalIdsResponse = await fetch(externalIdsUrl, {
        headers: {
          accept: "application/json",
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI5N2E3ZDA1ODJjZGYwYjM2ZTZhZjFlMjk3NjkwMDRjYyIsIm5iZiI6MTcyMTg1NTE4OS41NjUyNDIsInN1YiI6IjY2YTE2YzFhNzdjOTVkMzQxODE0YjYyOSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.tY9oXKuUvyhWgbuvFf8Z3CzdgtCqLCd7b7xqvdKfHZ8",
        },
      });
      const externalIdsData = await externalIdsResponse.json();
      const imdb_id = externalIdsData.imdb_id;

      if (!imdb_id) {
        throw new Error("IMDB ID not found");
      }

      try {
        let searchResponse;
        if (mediaType === "movie") {
          searchResponse = await fetch(
            `${apiRoot}/search/movie/${encodeURIComponent(
              title
            )}/${encodeURIComponent(imdb_id)}`
          );
        } else if (mediaType === "tv") {
          searchResponse = await fetch(
            `${apiRoot}/search/tv/${encodeURIComponent(title)}`
          );
        } else {
          throw new Error("Invalid media type");
        }

        const searchData = await searchResponse.json();

        if (!searchData || searchData.length === 0) {
          throw new Error("No results found");
        }

        let magnetResponse;
        if (mediaType === "movie") {
          magnetResponse = await fetch(
            `${apiRoot}/movie/magnet/${searchData[0].id}`
          );
        } else if (mediaType === "tv") {
          // Assuming the first season and episode for now
          magnetResponse = await fetch(`${apiRoot}/tv/magnet`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              tvShowTitle: searchData.name,
              seasonNumber: 1,
              episodeNumber: 1,
            }),
          });
        }

        if (magnetResponse) {
          const magnetData = await magnetResponse.json();
          console.log("Magnet data:", magnetData);
          if (magnetData && magnetData.magnetLink) {
            setMagnetLink(magnetData.magnetLink);
            magnetLinkFetchedRef.current = true;
          }
        } else {
          throw new Error("Failed to fetch magnet link");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchMagnetLink();
  }, [apiRoot]);

  useEffect(() => {
    if (magnetLink) {
      const streamVideo = async () => {
        try {
          const streamUrl = `${apiRoot}/stream?magnetLink=${encodeURIComponent(
            magnetLink
          )}&clientId=${clientId}`;
          if (videoRef.current) {
            videoRef.current.src = streamUrl;
            videoRef.current.load();
            console.log("Streaming started from:", streamUrl);
            setIsPlaying(false);
          } else {
            console.error("Video element not found");
          }
        } catch (error) {
          console.error("Error streaming video:", error);
        }
      };

      streamVideo();
    }

    return () => {
      // Cleanup function to destroy the stream when component unmounts
      if (magnetLink) {
        fetch(`${apiRoot}/destroy-stream`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ clientId }),
        })
          .then((response) => response.json())
          .then((data) => console.log("Stream destroyed:", data))
          .catch((error) => console.error("Error destroying stream:", error));
      }
    };
  }, [magnetLink, clientId, apiRoot]);

  useEffect(() => {
    const hideControlsTimer = () => {
      if (controlsTimeoutRef.current) clearTimeout(controlsTimeoutRef.current);
      controlsTimeoutRef.current = window.setTimeout(
        () => setShowControls(false),
        3000
      );
    };

    if (isPlaying) {
      hideControlsTimer();
    } else {
      setShowControls(true);
    }

    return () => {
      if (controlsTimeoutRef.current) clearTimeout(controlsTimeoutRef.current);
    };
  }, [isPlaying]);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current
          .play()
          .then(() => setIsPlaying(true))
          .catch(console.error);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  const handleSeek = (e) => {
    const time = parseFloat(e.target.value);
    setCurrentTime(time);
    if (videoRef.current) {
      videoRef.current.currentTime = time;
    }
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement && playerRef.current) {
      playerRef.current.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
    setIsFullscreen(!isFullscreen);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const skipForward = () => {
    if (videoRef.current) {
      videoRef.current.currentTime += 10;
    }
  };

  const skipBackward = () => {
    if (videoRef.current) {
      videoRef.current.currentTime -= 10;
    }
  };

  return (
    <div
      ref={playerRef}
      className="relative w-screen h-screen bg-black"
      onMouseMove={() => {
        setShowControls(true);
        if (controlsTimeoutRef.current)
          clearTimeout(controlsTimeoutRef.current);
        controlsTimeoutRef.current = window.setTimeout(
          () => setShowControls(false),
          3000
        );
      }}
    >
      <video
        ref={videoRef}
        className="w-full h-full"
        controls={true}
        controlsList="nodownload"
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={() => {
          if (videoRef.current) {
            setDuration(videoRef.current.duration);
          }
        }}
        onClick={handlePlayPause}
      />
      {showControls && false && (
        <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 p-4">
          <input
            type="range"
            min="0"
            max={duration}
            value={currentTime}
            onChange={handleSeek}
            className="w-full"
          />
          <div className="flex items-center justify-between mt-2">
            <button onClick={handlePlayPause}>
              {isPlaying ? <Pause /> : <Play />}
            </button>
            <div className="flex items-center">
              <button onClick={() => setVolume(volume === 0 ? 1 : 0)}>
                {volume === 0 ? <VolumeX /> : <Volume2 />}
              </button>
              <input
                type="range"
                min="0"
                max="1"
                step="0.1"
                value={volume}
                onChange={handleVolumeChange}
                className="ml-2"
              />
            </div>
            <div className="text-white">
              {formatTime(currentTime)} / {formatTime(duration)}
            </div>
            <button onClick={toggleFullscreen}>
              {isFullscreen ? <Minimize /> : <Maximize />}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FullscreenVideoPlayer;
