import axios from "axios";

// const apiRoot =
//   process.env.NODE_ENV !== "development"
//     ? "https://bingebandit.onrender.com"
//     : "http://localhost:8080";

const apiRoot = "https://bingebandit.onrender.com";

interface Movie {
  id: number;
  imdb_id: string;
  title: string;
  original_title: string;
  name: string;
  overview: string;
  poster_path: string;
  backdrop_path: string;
  media_type: string;
  adult: boolean;
  original_language: string;
  genre_ids: number[];
  popularity: number;
  release_date: string;
  video: boolean;
  vote_average: number;
  vote_count: number;
}

interface StreamingData {
  id: number;
  mediaType: string;
  title: string;
  imdbId: string;
}

export const fetchMovieDetails = async (
  movie: Movie
): Promise<StreamingData> => {
  const mediaType = movie.media_type || "movie";
  const externalIdsUrl = `https://api.themoviedb.org/3/${mediaType}/${movie.id}/external_ids`;
  const externalIdsResponse = await axios.get(externalIdsUrl, {
    headers: {
      accept: "application/json",
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI5N2E3ZDA1ODJjZGYwYjM2ZTZhZjFlMjk3NjkwMDRjYyIsIm5iZiI6MTcyMTg1NTE4OS41NjUyNDIsInN1YiI6IjY2YTE2YzFhNzdjOTVkMzQxODE0YjYyOSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.tY9oXKuUvyhWgbuvFf8Z3CzdgtCqLCd7b7xqvdKfHZ8",
    },
  });
  const imdb_id = externalIdsResponse.data.imdb_id;

  if (!imdb_id) {
    throw new Error("IMDB ID not found");
  }

  let searchResponse;
  if (mediaType === "movie") {
    const url = `https://yts.mx/api/v2/list_movies.json?query_term=${encodeURIComponent(
      movie.title || ""
    )}&imdb_code=${encodeURIComponent(imdb_id)}`;
    console.log("Searching for movie:", url);

    searchResponse = await axios.get(url);

    const data = searchResponse.data;
    if (data.data && data.data.movies) {
      let filteredMovies = data.data.movies.filter(
        (movie: { imdb_code: string }) => movie.imdb_code === imdb_id
      );

      if (filteredMovies.length === 0) {
        const releaseYear = movie.release_date
          ? new Date(movie.release_date).getFullYear()
          : "";
        const queryWithYear = `${movie.title} ${releaseYear}`.trim();
        const retryUrl = `https://yts.mx/api/v2/list_movies.json?query_term=${encodeURIComponent(
          queryWithYear
        )}&imdb_code=${encodeURIComponent(imdb_id)}`;
        console.log("Retrying search with year:", retryUrl);

        const retryResponse = await axios.get(retryUrl);
        const retryData = retryResponse.data;

        if (retryData.data && retryData.data.movies) {
          filteredMovies = retryData.data.movies.filter(
            (movie: { imdb_code: string }) => movie.imdb_code === imdb_id
          );
        }
      }

      searchResponse.data.data.movies = filteredMovies;
    }
  } else if (mediaType === "tv") {
    searchResponse = await axios.get(
      `https://api.themoviedb.org/3/search/tv?api_key=97a7d0582cdf0b36e6af1e29769004cc&query=${encodeURIComponent(
        movie.name || ""
      )}`
    );
  } else {
    throw new Error("Invalid media type");
  }

  const searchData = searchResponse.data;

  if (
    !searchData ||
    (mediaType === "movie" &&
      (!searchData.data ||
        !searchData.data.movies ||
        searchData.data.movies.length === 0)) ||
    (mediaType === "tv" &&
      (!searchData.results || searchData.results.length === 0))
  ) {
    throw new Error("No results found");
  }

  const resultId =
    mediaType === "movie"
      ? searchData.data.movies[0].id
      : searchData.results[0].id;
  const resultTitle =
    mediaType === "movie" ? movie.title || "" : movie.name || "";

  console.log({
    id: resultId,
    mediaType,
    title: resultTitle,
    imdbId: imdb_id,
  });

  return {
    id: resultId,
    mediaType,
    title: resultTitle,
    imdbId: imdb_id,
  };
};

export const startStreaming = async (
  streamingData: StreamingData
): Promise<string> => {
  let magnetLink: string | undefined;
  if (streamingData.mediaType === "movie") {
    const response = await axios.get(
      `https://yts.mx/api/v2/movie_details.json?imdb_id=${streamingData.imdbId}`
    );
    const data = response.data;
    if (data.data && data.data.movie && data.data.movie.torrents) {
      const validTorrents = data.data.movie.torrents.filter(
        (torrent: { quality: string }) => torrent.quality !== "3D"
      );
      if (validTorrents.length > 0) {
        const smallestTorrent = validTorrents.reduce(
          (smallest: { size: string }, current: { size: string }) => {
            const smallestSize = parseFloat(smallest.size.replace(" GB", ""));
            const currentSize = parseFloat(current.size.replace(" GB", ""));
            return currentSize < smallestSize ? current : smallest;
          }
        );
        magnetLink = smallestTorrent.url;
      }
    }
  } else if (streamingData.mediaType === "tv") {
    const searchQuery = `${streamingData.title} s01e01`;
    const response = await axios.get(
      `https://apibay.org/q.php?q=${encodeURIComponent(searchQuery)}`
    );
    const data = response.data;
    if (Array.isArray(data) && data.length > 0) {
      const torrentWithFiles = data[0];
      magnetLink = `magnet:?xt=urn:btih:${
        torrentWithFiles.info_hash
      }&dn=${encodeURIComponent(torrentWithFiles.name)}`;
    }
  }

  if (magnetLink) {
    const streamUrl = `${apiRoot}/stream?magnetLink=${encodeURIComponent(
      magnetLink
    )}&clientId=${streamingData.id}`;
    return streamUrl;
  } else {
    throw new Error("Failed to fetch magnet link");
  }
};
