import React from "react";
import { createRoot } from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import "./styles/index.css";
import App from "./App";
import ReactGA from "react-ga4";

ReactGA.initialize("G-VT7H4MC676");

const root = createRoot(document.getElementById("root") as HTMLElement);

const isDev = process.env.NODE_ENV === "development";

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="jenni.us.auth0.com"
      clientId="sKbdU2UHmSXx0cOJUmE5axAkOwMyvnef"
      authorizationParams={{
        redirect_uri: isDev
          ? "http://localhost:3000/pricing"
          : `https://joinchatgptplus.com/pricing`,
      }}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);
