import React, { Component } from "react";

class VideoApp extends Component {
  render() {
    return (
      <div>
        <video
          controls
          src={this.props.mp4file}
          poster="https://peertube.cpy.re/static/thumbnails/e2651856-4809-408a-99d4-b85b01fefb09.jpg"
          width="720"
          height="420"
        />
      </div>
    );
  }
}

class App extends Component {
  state = {
    torrentInfoHash: "",
    torrentMagnetURI: "",
    torrentName: "",
    torrentProgress: "",
    torrentFiles: [],
    mp4file: "",
  };

  async componentDidMount() {
    // Sintel, a free, Creative Commons movie
    var torrentId =
      "magnet:?xt=urn:btih:08ada5a7a6183aae1e09d831df6748d566095a10&dn=Sintel&tr=udp%3A%2F%2Fexplodie.org%3A6969&tr=udp%3A%2F%2Ftracker.coppersurfer.tk%3A6969&tr=udp%3A%2F%2Ftracker.empire-js.us%3A1337&tr=udp%3A%2F%2Ftracker.leechers-paradise.org%3A6969&tr=udp%3A%2F%2Ftracker.opentrackr.org%3A1337&tr=wss%3A%2F%2Ftracker.btorrent.xyz&tr=wss%3A%2F%2Ftracker.fastcast.nz&tr=wss%3A%2F%2Ftracker.openwebtorrent.com&ws=https%3A%2F%2Fwebtorrent.io%2Ftorrents%2F&xs=https%3A%2F%2Fwebtorrent.io%2Ftorrents%2Fsintel.torrent";

    const WebTorrent = await import("https://esm.sh/webtorrent");
    const client = new WebTorrent.default();

    client.on("error", (err) => {
      console.log("[+] Webtorrent error: " + err.message);
    });

    client.add(torrentId, (torrent) => {
      const interval = setInterval(() => {
        this.setState({
          torrentProgress: (torrent.progress * 100).toFixed(1) + "%",
        });
      }, 5000);
      torrent.on("done", () => {
        console.log("Progress: 100%");
        clearInterval(interval);
      });

      this.setState({
        torrentInfoHash: torrent.infoHash,
        torrentMagnetURI: torrent.magnetURI,
        torrentName: torrent.name,
        torrentFiles: torrent.files,
      });

      var mp4File = torrent.files.find(function (file) {
        return file.name.endsWith(".mp4");
      });

      if (mp4File) {
        mp4File.streamTo((err, url) => {
          if (err) {
            console.error("Error getting stream URL:", err);
            return;
          }
          this.setState({ mp4file: url });
        });
      } else {
        console.error("No MP4 file found in the torrent");
      }

      if (mp4File && mp4File.path) {
        console.log(mp4File.path);
      } else {
        console.error("MP4 file or its path is undefined");
      }
    });
  }

  render() {
    const mp4file = this.state.mp4file;
    return (
      <div>
        <VideoApp mp4file={mp4file} />
        <h1>{this.state.torrentName}</h1>
        <p>
          <b>Torrent Info Hash: </b>
          {this.state.torrentInfoHash}
        </p>
        <p>
          <b>Torrent Progress: </b>
          {this.state.torrentProgress}
        </p>
      </div>
    );
  }
}

export default App;
