import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Search,
  X,
  Play,
  Pause,
  Volume2,
  VolumeX,
  Maximize,
} from "lucide-react";
import axios from "axios";
import { fetchMovieDetails, startStreaming } from "./streamingUtils";

// const apiRoot =
//   process.env.NODE_ENV !== "development"
//     ? "https://bingebandit.onrender.com"
//     : "http://localhost:8080";

const apiRoot = "https://bingebandit.onrender.com";

interface Movie {
  id: number;
  imdb_id: string;
  title: string;
  original_title: string;
  name: string;
  overview: string;
  poster_path: string;
  backdrop_path: string;
  media_type: string;
  adult: boolean;
  original_language: string;
  genre_ids: number[];
  popularity: number;
  release_date: string;
  video: boolean;
  vote_average: number;
  vote_count: number;
}

const VideoPlayer: React.FC<{
  streamUrl: string;
  streamId: string | null;
  onClose: () => void;
  movieTitle: string; // Add this new prop
}> = ({ streamUrl, streamId, onClose, movieTitle }) => {
  // Add movieTitle to the destructured props
  const [isPlaying, setIsPlaying] = useState(true);
  const [volume, setVolume] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [showControls, setShowControls] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const videoRef = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<HTMLDivElement>(null);
  const controlsTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (streamUrl && videoRef.current) {
      videoRef.current.src = streamUrl;
      videoRef.current.load();
      videoRef.current.play().catch(console.error);
    }
  }, [streamUrl]);

  useEffect(() => {
    const hideControlsTimer = () => {
      if (controlsTimeoutRef.current) clearTimeout(controlsTimeoutRef.current);
      controlsTimeoutRef.current = setTimeout(
        () => setShowControls(false),
        2000
      );
    };

    const handleMouseMove = () => {
      setShowControls(true);
      hideControlsTimer();
    };

    document.addEventListener("mousemove", handleMouseMove);
    hideControlsTimer();

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      if (controlsTimeoutRef.current) clearTimeout(controlsTimeoutRef.current);
    };
  }, []);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current
          .play()
          .then(() => setIsPlaying(true))
          .catch(console.error);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    const time = parseFloat(e.target.value);
    setCurrentTime(time);
    if (videoRef.current) {
      videoRef.current.currentTime = time;
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleClose = async () => {
    try {
      onClose();
      axios.post(`${apiRoot}/destroy-stream`, { clientId: streamId });
    } catch (error) {
      console.error("Error destroying stream:", error);
    }
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      if (playerRef.current?.requestFullscreen) {
        playerRef.current.requestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  return (
    <div
      className="fixed inset-0 bg-black z-50 flex items-center justify-center"
      ref={playerRef}
    >
      <div className="relative w-full h-full">
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="relative w-16 h-16">
              <div className="absolute inset-0 border-4 border-red-600 opacity-25 rounded-full"></div>
              <div className="absolute inset-0 border-4 border-t-black rounded-full animate-spin"></div>
            </div>
          </div>
        )}
        <video
          ref={videoRef}
          className="w-full h-full object-contain"
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={() => {
            if (videoRef.current) {
              setDuration(videoRef.current.duration);
              setIsLoading(false);
            }
          }}
          onClick={handlePlayPause}
        />
        {showControls && (
          <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent">
            {/* Movie title */}
            <div className="absolute top-4 left-0 right-0 text-center">
              <h2 className="text-white text-2xl font-medium">{movieTitle}</h2>
            </div>

            {/* Close button */}
            <button
              onClick={handleClose}
              className="absolute top-4 left-4 text-white hover:text-gray-300"
            >
              <X size={24} />
            </button>

            {/* Bottom controls */}
            <div className="absolute bottom-0 left-0 right-0 px-4 py-6">
              <input
                type="range"
                min="0"
                max={duration}
                value={currentTime}
                onChange={handleSeek}
                className="w-full h-1 bg-gray-600 appearance-none cursor-pointer"
                style={{
                  backgroundImage: `linear-gradient(to right, #fff 0%, #fff ${
                    (currentTime / duration) * 100
                  }%, #4D4D4D ${
                    (currentTime / duration) * 100
                  }%, #4D4D4D 100%)`,
                }}
              />
              <div className="flex items-center justify-between mt-4">
                <div className="flex items-center space-x-4">
                  <button
                    onClick={handlePlayPause}
                    className="text-white hover:text-gray-300"
                  >
                    {isPlaying ? <Pause size={24} /> : <Play size={24} />}
                  </button>
                  <div className="flex items-center">
                    <button
                      onClick={() => setVolume(volume === 0 ? 1 : 0)}
                      className="text-white hover:text-gray-300"
                    >
                      {volume === 0 ? (
                        <VolumeX size={24} />
                      ) : (
                        <Volume2 size={24} />
                      )}
                    </button>
                    <input
                      type="range"
                      min="0"
                      max="1"
                      step="0.1"
                      value={volume}
                      onChange={handleVolumeChange}
                      className="ml-2 w-20 h-1 bg-gray-600 appearance-none cursor-pointer"
                      style={{
                        backgroundImage: `linear-gradient(to right, white 0%, white ${
                          volume * 100
                        }%, #4D4D4D ${volume * 100}%, #4D4D4D 100%)`,
                      }}
                    />
                  </div>
                  <div className="text-white text-sm">
                    {formatTime(currentTime)} / {formatTime(duration)}
                  </div>
                </div>

                {/* Maximize button */}
                <button
                  onClick={toggleFullScreen}
                  className="text-white hover:text-gray-300"
                >
                  <Maximize size={24} />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const MovieCard: React.FC<{
  movie: Movie;
  onPlay: (streamUrl: string, streamId: string, movieTitle: string) => void;
}> = ({ movie, onPlay }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [streamingData, setStreamingData] = useState<{
    id: number;
    mediaType: string;
    title: string;
    imdbId: string;
  } | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleMouseEnter = async () => {
    setIsHovered(true);
    try {
      const details = await fetchMovieDetails(movie);
      setStreamingData(details);
      setError(null);
    } catch (err) {
      console.error("Error fetching movie details:", err);
      setError("Unable to fetch movie details");
      setStreamingData(null);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setStreamingData(null);
    setError(null);
  };

  const handleClick = async () => {
    if (streamingData) {
      try {
        const streamUrl = await startStreaming(streamingData);
        onPlay(streamUrl, streamingData.id.toString(), streamingData.title);
      } catch (err) {
        console.error("Error starting streaming:", err);
        setError("Unable to start streaming");
      }
    }
  };

  return (
    <div
      className="relative group overflow-hidden cursor-pointer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <img
        src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
        alt={movie.title || movie.name}
        className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
      />
      <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-40 transition-opacity duration-300 opacity-0 group-hover:opacity-100"></div>
      {isHovered && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-white text-center">
            {/* <h3 className="text-xl font-bold">{movie.title || movie.name}</h3> */}
            {/* {error ? (
              <p className="mt-2 text-red-500">{error}</p>
            ) : (
              <p className="mt-2">Click to play</p>
            )} */}
          </div>
        </div>
      )}
    </div>
  );
};

const LandingPage: React.FC = () => {
  const [movies, setMovies] = useState<Movie[]>([]);
  const [showSearch, setShowSearch] = useState<boolean>(true);
  const [lastScrollY, setLastScrollY] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const hasFetchedFirstBatch = React.useRef<boolean>(false);
  const [isSearchExpanded, setIsSearchExpanded] = useState<boolean>(false);
  const [heroTrailer, setHeroTrailer] = useState<string | null>(null);
  const [heroBackground, setHeroBackground] = useState<string | null>(null);
  const hasSetHeroTrailer = useRef<boolean>(false);
  const [isTrailerPlaying, setIsTrailerPlaying] = useState<boolean>(false);
  const [heroTitle, setHeroTitle] = useState<string>("");
  const [heroDescription, setHeroDescription] = useState<string>("");
  const [currentStreamUrl, setCurrentStreamUrl] = useState<string | null>(null);
  const [streamId, setStreamId] = useState<string | null>(null);
  const [firstMovie, setFirstMovie] = useState<Movie | null>(null);
  const [activeMovieTitle, setActiveMovieTitle] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [originalMovies, setOriginalMovies] = useState<Movie[]>([]);

  const fetchTrendingMovies = useCallback(
    async (pageNumber: number) => {
      if (loading || isFetching) return;
      setLoading(true);
      setIsFetching(true);
      try {
        const url = `https://api.themoviedb.org/3/trending/all/week?language=en-US&page=${pageNumber}`;
        console.log("Fetching trending movies:", url);
        const response = await axios.get(url, {
          headers: {
            accept: "application/json",
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI5N2E3ZDA1ODJjZGYwYjM2ZTZhZjFlMjk3NjkwMDRjYyIsIm5iZiI6MTcyMTg1NTE4OS41NjUyNDIsInN1YiI6IjY2YTE2YzFhNzdjOTVkMzQxODE0YjYyOSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.tY9oXKuUvyhWgbuvFf8Z3CzdgtCqLCd7b7xqvdKfHZ8",
          },
        });

        const newMovies = response.data.results as Movie[];
        const currentDate = new Date();
        const sevenDaysAgo = new Date(
          currentDate.getTime() - 1 * 24 * 60 * 60 * 1000
        );
        const filteredMovies = newMovies.filter((movie: Movie) => {
          const releaseDate = new Date(movie.release_date);
          return releaseDate < currentDate && releaseDate <= sevenDaysAgo;
        });

        if (
          pageNumber === 1 &&
          filteredMovies.length > 0 &&
          !hasSetHeroTrailer.current
        ) {
          const firstMovie = filteredMovies[0];
          setFirstMovie(firstMovie);
          const heroTitle = firstMovie.title || firstMovie.name;
          const heroDescription = firstMovie.overview;
          setHeroTitle(heroTitle);
          setHeroDescription(heroDescription);

          const mediaType = firstMovie.media_type || "movie";
          const externalIdsUrl = `https://api.themoviedb.org/3/${mediaType}/${firstMovie.id}/external_ids`;
          const externalIdsResponse = await axios.get(externalIdsUrl, {
            headers: {
              accept: "application/json",
              Authorization:
                "Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI5N2E3ZDA1ODJjZGYwYjM2ZTZhZjFlMjk3NjkwMDRjYyIsIm5iZiI6MTcyMTg1NTE4OS41NjUyNDIsInN1YiI6IjY2YTE2YzFhNzdjOTVkMzQxODE0YjYyOSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.tY9oXKuUvyhWgbuvFf8Z3CzdgtCqLCd7b7xqvdKfHZ8",
            },
          });
          const imdb_id = externalIdsResponse.data.imdb_id;

          if (imdb_id) {
            let searchData;
            if (mediaType === "movie") {
              const response = await axios.get(
                `https://yts.mx/api/v2/list_movies.json?query_term=${encodeURIComponent(
                  firstMovie.title
                )}&imdb_code=${encodeURIComponent(imdb_id)}`
              );
              searchData = response.data.data.movies;
            } else {
              const response = await axios.get(
                `https://api.themoviedb.org/3/search/tv?api_key=97a7d0582cdf0b36e6af1e29769004cc&query=${encodeURIComponent(
                  firstMovie.name
                )}`
              );
              searchData = response.data.results;
            }

            if (searchData && searchData.length > 0) {
              const yt_trailer_code = searchData[0].yt_trailer_code;
              console.log("yt_trailer_code:", yt_trailer_code);

              if (yt_trailer_code) {
                const youtubeUrl = `https://www.youtube.com/watch?v=${yt_trailer_code}`;

                try {
                  const streamResponse = await axios.get(
                    `${apiRoot}/youtube-stream`,
                    {
                      params: { url: youtubeUrl },
                    }
                  );

                  console.log("YouTube stream response:", streamResponse.data);

                  if (streamResponse.data && streamResponse.data.url) {
                    setHeroTrailer(streamResponse.data.url);
                    console.log("Actual MP4 URL:", streamResponse.data.url);
                  } else {
                    console.error(
                      "Failed to get MP4 URL from YouTube stream endpoint"
                    );
                  }
                } catch (error) {
                  console.error("Error fetching YouTube stream URL:", error);
                }
                hasSetHeroTrailer.current = true;
              }

              if (searchData[0].background_image) {
                setHeroBackground(searchData[0].background_image);
              }
            }
          }
        }

        setMovies((prevMovies) => {
          const uniqueMovies = filteredMovies.filter(
            (newMovie: Movie) =>
              !prevMovies.some((prevMovie) => prevMovie.id === newMovie.id)
          );
          return [...prevMovies, ...uniqueMovies];
        });

        const twoMonthsAgo = new Date();
        twoMonthsAgo.setMonth(currentDate.getMonth() - 2);

        const updatedMovies = await Promise.all(
          filteredMovies.map(async (movie: Movie) => {
            const releaseDate = new Date(movie.release_date);

            if (releaseDate > currentDate) {
              return movie;
            }

            if (releaseDate < twoMonthsAgo) {
              return null;
            }

            try {
              const movieDetails = await fetchMovieDetails(movie);
              if (movieDetails) {
                return null;
              }
            } catch (error) {
              console.error(
                `Error fetching details for movie ${movie.id}:`,
                error
              );
            }
            return movie;
          })
        );

        console.log("Updated movies:", updatedMovies);

        setMovies((prevMovies) => {
          const moviesToKeep = prevMovies.filter(
            (prevMovie) =>
              !updatedMovies.some(
                (updatedMovie) =>
                  updatedMovie && updatedMovie.id === prevMovie.id
              )
          );
          return moviesToKeep;
        });
        setPage(pageNumber);
        setLastPage(response.data.total_pages);
      } catch (error) {
        console.error("Error fetching trending movies:", error);
      } finally {
        setLoading(false);
        setIsFetching(false);
      }
    },
    [loading, isFetching]
  );

  useEffect(() => {
    const fetchInitialPages = async () => {
      await fetchTrendingMovies(1);
      await fetchTrendingMovies(2);
      await fetchTrendingMovies(3);

      hasFetchedFirstBatch.current = true;
    };

    if (!hasFetchedFirstBatch.current) fetchInitialPages();

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setShowSearch(false);
      } else {
        setShowSearch(true);
      }
      setLastScrollY(currentScrollY);

      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 500
      ) {
        if (page < lastPage) {
          fetchTrendingMovies(page + 1);
        }
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY, page, fetchTrendingMovies]);

  const handlePlayMovie = (
    streamUrl: string,
    streamId: string,
    movieTitle: string
  ) => {
    setCurrentStreamUrl(streamUrl);
    setStreamId(streamId);
    setActiveMovieTitle(movieTitle);
  };

  const handleCloseVideo = () => {
    setCurrentStreamUrl(null);
  };

  const [isHovered, setIsHovered] = useState(false);
  const [streamingData, setStreamingData] = useState<{
    id: number;
    mediaType: string;
    title: string;
    imdbId: string;
  } | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleMouseEnter = async () => {
    setIsHovered(true);
    try {
      const details = await fetchMovieDetails(firstMovie || ({} as Movie));
      setStreamingData(details);
      setError(null);
    } catch (err) {
      console.error("Error fetching movie details:", err);
      setError("Unable to fetch movie details");
      setStreamingData(null);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setStreamingData(null);
    setError(null);
  };

  const handleClick = async () => {
    if (streamingData) {
      try {
        const streamUrl = await startStreaming(streamingData);
        handlePlayMovie(
          streamUrl,
          streamingData.id.toString(),
          streamingData.title
        );
      } catch (err) {
        console.error("Error starting streaming:", err);
        setError("Unable to start streaming");
      }
    }
  };

  const handleSearch = async (query: string) => {
    setSearchQuery(query);
    if (query.trim() === "") {
      setMovies(originalMovies);
      return;
    }

    try {
      const response = await axios.get(
        `https://api.themoviedb.org/3/search/movie?api_key=97a7d0582cdf0b36e6af1e29769004cc&query=${encodeURIComponent(
          query
        )}`
      );
      setMovies(response.data.results);
    } catch (error) {
      console.error("Error searching movies:", error);
    }
  };

  const handleSearchExpand = () => {
    if (!isSearchExpanded) {
      setOriginalMovies(movies);
    }
    setIsSearchExpanded(!isSearchExpanded);
    if (!isSearchExpanded) {
      setTimeout(() => {
        const inputElement = document.querySelector(
          'input[type="text"]'
        ) as HTMLInputElement;
        if (inputElement) inputElement.focus();
      }, 0);
    } else {
      setSearchQuery("");
      setMovies(originalMovies);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white relative">
      <div className="fixed top-0 left-0 right-0 z-20 bg-black bg-opacity-0 py-4">
        <div className="flex items-center max-w-8xl mx-auto px-8">
          <img
            src="https://storage.googleapis.com/bingebandit.appspot.com/bandit.png"
            alt="BingeBandit Logo"
            className="h-12 md:h-16 mr-4"
          />
          <div className="relative flex-grow ml-4">
            <div
              className={`flex items-center justify-end transition-all duration-300 ${
                isSearchExpanded ? "w-full" : "w-auto"
              }`}
            >
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
                className={`py-4 px-6 rounded-[2px] mr-4 bg-black bg-opacity-70 text-white placeholder-gray-400 focus:outline-none focus:shadow-outline transition-all duration-300 text-lg max-w-[375px] ${
                  isSearchExpanded
                    ? "w-full opacity-100 border border-white"
                    : "w-0 opacity-0"
                }`}
                autoFocus={isSearchExpanded}
              />
              {isSearchExpanded ? (
                <X
                  className="text-white cursor-pointer"
                  size={24}
                  onClick={handleSearchExpand}
                />
              ) : (
                <Search
                  className="text-white cursor-pointer"
                  size={24}
                  onClick={handleSearchExpand}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="relative w-full h-[56.25vw] md:h-[46vw] max-h-[90vh] min-h-[600px] sm:min-h-[600px] md:min-h-[600px] overflow-hidden"
      >
        <div
          className="absolute inset-0 bg-cover bg-center transition-opacity duration-1000"
          style={{
            backgroundImage: `url(${heroBackground})`,
            opacity: isTrailerPlaying ? 0 : 1,
          }}
        />
        <div
          className="absolute inset-[-105px] transition-opacity duration-1000"
          style={{ opacity: isTrailerPlaying ? 1 : 0 }}
        >
          {heroTrailer && (
            <video
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 min-w-full min-h-full w-auto h-auto object-cover"
              autoPlay
              muted
              loop
              playsInline
              onCanPlay={(event) => {
                setIsTrailerPlaying(true);
                const video = event.target as HTMLVideoElement;
                video
                  .play()
                  .catch((error) =>
                    console.error("Error playing video:", error)
                  );
              }}
              onLoadedMetadata={(e) => {
                const video = e.target as HTMLVideoElement;
                // video.muted = false;
              }}
            >
              <source src={`${heroTrailer}`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>

        {/* Gradient Overlay */}
        <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent" />

        {/* Hero Content */}
        <div className="absolute bottom-0 left-0 p-8 w-full md:w-1/2">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">{heroTitle}</h1>
          <p className="text-lg md:text-xl mb-6">{heroDescription}</p>
          <button
            className="bg-white text-black py-2 px-6 rounded-md text-lg font-semibold hover:bg-opacity-80 transition-colors duration-300"
            onClick={handleClick}
          >
            Watch Now
          </button>
        </div>
      </div>

      {/* Movie Grid */}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-0">
        {movies.map((movie) => (
          <MovieCard key={movie.id} movie={movie} onPlay={handlePlayMovie} />
        ))}
      </div>

      {/* Video Player */}
      {currentStreamUrl && (
        <VideoPlayer
          streamUrl={currentStreamUrl}
          streamId={streamId}
          onClose={handleCloseVideo}
          movieTitle={activeMovieTitle || ""}
        />
      )}
    </div>
  );
};

export default LandingPage;
